// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DownloadIcons-module__RuQw6QGySV0rTeaA32hg{width:25px;height:auto;margin-right:12px}.DownloadIcons-module__mtJe_o0qHliuydSPUvsT,.DownloadIcons-module__RuQw6QGySV0rTeaA32hg{cursor:default}.DownloadIcons-module__yFJyGpQCnuN4h0ZcggXm{display:flex;align-items:center;min-height:80px}", "",{"version":3,"sources":["webpack://./containers/MobileAppVerification/parts/DownloadIcons/DownloadIcons.module.scss"],"names":[],"mappings":"AAAA,4CACE,UAAA,CACA,WAAA,CACA,iBAAA,CAGF,wFAEE,cAAA,CAGF,4CACE,YAAA,CACA,kBAAA,CACA,eAAA","sourcesContent":[".icon {\n  width: 25px;\n  height: auto;\n  margin-right: 12px;\n}\n\n.icon-text,\n.icon {\n  cursor: default;\n}\n\n.icon-block {\n  display: flex;\n  align-items: center;\n  min-height: 80px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "DownloadIcons-module__RuQw6QGySV0rTeaA32hg",
	"icon-text": "DownloadIcons-module__mtJe_o0qHliuydSPUvsT",
	"icon-block": "DownloadIcons-module__yFJyGpQCnuN4h0ZcggXm"
};
export default ___CSS_LOADER_EXPORT___;
