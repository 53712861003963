// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GrafanaTeamSelect-module__pDwfaLISbqVLqW5r7Tcj{width:200px;right:0;position:absolute;padding:16px 0;margin-right:24px}.GrafanaTeamSelect-module__MjLSUmAIVG6rTPHJNhRm{right:14px;top:12px}.GrafanaTeamSelect-module__ItJRa3KA_WVPqwYeEdwh{right:32px;top:36px}.GrafanaTeamSelect-module__JVj4tuphCIWyMbXVcwaQ{display:flex}.GrafanaTeamSelect-module__yDq7jAfMfiTqFjY8cdzl{margin-left:auto}.GrafanaTeamSelect-module__TRUeiqMdxOzCuRLnQict{margin-left:4px}", "",{"version":3,"sources":["webpack://./containers/GrafanaTeamSelect/GrafanaTeamSelect.module.scss"],"names":[],"mappings":"AAAA,gDACE,WAAA,CACA,OAAA,CACA,iBAAA,CACA,cAAA,CACA,iBAAA,CAEA,gDACE,UAAA,CACA,QAAA,CAEF,gDACE,UAAA,CACA,QAAA,CAIJ,gDACE,YAAA,CAGF,gDACE,gBAAA,CAGF,gDACE,eAAA","sourcesContent":[".teamSelect {\n  width: 200px;\n  right: 0;\n  position: absolute;\n  padding: 16px 0;\n  margin-right: 24px;\n\n  &--topRight {\n    right: 14px;\n    top: 12px;\n  }\n  &--topRightIncident {\n    right: 32px;\n    top: 36px;\n  }\n}\n\n.teamSelectLabel {\n  display: flex;\n}\n\n.teamSelectLink {\n  margin-left: auto;\n}\n\n.teamSelectInfo {\n  margin-left: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"teamSelect": "GrafanaTeamSelect-module__pDwfaLISbqVLqW5r7Tcj",
	"teamSelect--topRight": "GrafanaTeamSelect-module__MjLSUmAIVG6rTPHJNhRm",
	"teamSelect--topRightIncident": "GrafanaTeamSelect-module__ItJRa3KA_WVPqwYeEdwh",
	"teamSelectLabel": "GrafanaTeamSelect-module__JVj4tuphCIWyMbXVcwaQ",
	"teamSelectLink": "GrafanaTeamSelect-module__yDq7jAfMfiTqFjY8cdzl",
	"teamSelectInfo": "GrafanaTeamSelect-module__TRUeiqMdxOzCuRLnQict"
};
export default ___CSS_LOADER_EXPORT___;
