// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DefaultPageLayout-module__ZZMG_VdpTmbEvZsH8S4g{height:100%;display:flex;flex-direction:column}.DefaultPageLayout-module__ZZMG_VdpTmbEvZsH8S4g .DefaultPageLayout-module__lO4ZZ4_mmqVqQYlzIetF{display:flex;gap:10px}.DefaultPageLayout-module__ZZMG_VdpTmbEvZsH8S4g .DefaultPageLayout-module__VgVZ1u2kqEaSCle5cMLl{margin:24px 0}.DefaultPageLayout-module__ZZMG_VdpTmbEvZsH8S4g .filter-table td{white-space:break-spaces;line-height:20px;height:auto}.DefaultPageLayout-module__I6QTvlo03GYEUBdW1ImC{color:var(--primary-text-link)}", "",{"version":3,"sources":["webpack://./containers/DefaultPageLayout/DefaultPageLayout.module.scss"],"names":[],"mappings":"AAAA,gDACE,WAAA,CACA,YAAA,CACA,qBAAA,CAEA,gGACE,YAAA,CACA,QAAA,CAGF,gGACE,aAAA,CAMJ,iEACE,wBAAA,CACA,gBAAA,CACA,WAAA,CAGF,gDACE,8BAAA","sourcesContent":[".root {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n\n  .alerts_horizontal {\n    display: flex;\n    gap: 10px;\n  }\n\n  .alert {\n    margin: 24px 0;\n  }\n}\n\n/* --- GRAFANA UI TUNINGS --- */\n\n.root :global(.filter-table) td {\n  white-space: break-spaces;\n  line-height: 20px;\n  height: auto;\n}\n\n.instructions-link {\n  color: var(--primary-text-link);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "DefaultPageLayout-module__ZZMG_VdpTmbEvZsH8S4g",
	"alerts_horizontal": "DefaultPageLayout-module__lO4ZZ4_mmqVqQYlzIetF",
	"alert": "DefaultPageLayout-module__VgVZ1u2kqEaSCle5cMLl",
	"instructions-link": "DefaultPageLayout-module__I6QTvlo03GYEUBdW1ImC"
};
export default ___CSS_LOADER_EXPORT___;
